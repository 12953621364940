import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import * as Flex from "@twilio/flex-ui";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const mountNode = document.getElementById("root");

window.onload = () => {
  const predefinedConfig = window.appConfig || {};

  const configuration = {
    ...predefinedConfig,
    logLevel: process.env.REACT_APP_FLEX_LOGLEVEL,
  };

  // Add account SID from build env vars
  configuration.sso.accountSid = process.env.REACT_APP_ACCOUNT_SID;

  Flex.progress(mountNode)
    .provideLoginInfo(configuration, mountNode)
    .then(() => Flex.Manager.create(configuration))
    .then((manager) => renderApp(manager))
    .catch((error) => handleError(error));
};

function renderApp(manager) {
  ReactDOM.render(<App manager={manager} />, mountNode);
}

function handleError(error) {
  Flex.errorPage(error, mountNode);
  console.error("Failed to initialize Flex", error);
}

registerServiceWorker();
